<template>
    <div>
        <div class="base-width">
            <question-content @answer="addQuestion" :answer="answer">
                        <span slot="editQuestion">
                            <span>&nbsp;&emsp;</span>
                             <el-button v-self="{username:answer.username&&answer.username.username}"
                                        :loading="$store.state.loading" @click="editAnswer" size="small"
                                        icon="el-icon-plus">修改问题</el-button>

                            <!--                            <el-button :type="answer.is_hot?'':'primary'"-->
                            <!--                                       v-if="answer.review_status===200"-->
                            <!--                                       v-access="{url:'/bbs/answer/hot',method:'post'}"-->
                            <!--                                       :loading="$store.state.loading" @click="addHot(1)" size="small"-->
                            <!--                            > <icon-font margin-right="0"-->
                            <!--                                         icon-class="remen1"></icon-font> {{ answer.is_hot ? '取消热门' : '设为热门' }}</el-button>-->

                            <el-button :type="answer.is_recommend?'':'primary'"
                                       v-if="answer.review_status===200"
                                       v-access="{url:'/bbs/answer/hot',method:'post'}"
                                       :loading="$store.state.loading" @click="addHot(2)" size="small"
                            > <icon-font margin-right="0"
                                         icon-class="tuijian"></icon-font> {{
                                    answer.is_recommend ? '取消推荐' : '设为推荐'
                                }}</el-button>
                        </span>
            </question-content>
            <div v-if="control.edit" class="re-editor base-bg">
                <wanedit ref="editor" @change="change" :config="editorConfig"></wanedit>
                <div class="editor-footer">
                    <div class="tip">
                        {{ control.text }}
                    </div>
                    <div class="btn-area">
                        <el-button @click="cancelReply" type="default" size="small">取消</el-button>
                        <el-button :loading="$store.state.loading" @click="addReply" type="primary"
                                   size="small">
                            提交
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="reply-list base-bg">
                <div class="top-sum">共计 <span class="num">{{ reply.total || 0 }}</span> 回复</div>
                <div class="bbs-second-color" v-if="reply.length===0">
                    还没有人回复过这个主题，赶快来帮助TA解答一下吧
                </div>
                <reply-item @editReply="editReply(index)" @confirmReply="confirmReply(index,item)"
                            @addComment="addComment" :index="index"
                            v-for="(item,index) in reply.data"
                            :key="item.reply_id" :reply="item"></reply-item>
            </div>
            <div class="connected base-bg">
                <question-connected></question-connected>
            </div>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
        <verify @verify="verifySuccess" :answer="answer" v-if="control.verify&&control.type==='answer'"></verify>
    </div>
</template>
<script>
import QuestionContent from "@/components/wenda/left/question-content";
import ReplyItem from "@/components/wenda/left/reply-item";
import QuestionConnected from "@/components/wenda/left/question-connected.vue";
import wanedit from "@/components/common/wanedit";
import storage from "@/utils/storage";
import F from '@/fields/fields';
import Verify from "@/components/wenda/left/verify.vue";
export default {
    name: "index",
    components: {
        QuestionContent, ReplyItem, wanedit, QuestionConnected, Verify
    },
    mounted() {

    },
    created() {
        this.getDetail();
    },
    data: function () {
        return {
            control: {
                edit: false,
                text: '',//显示错误提示使用
                verify: !!this.$route.query.verify,
                type: this.$route.query.type,
                //修改问题的时候，可以修改这些字段
                fields: ['answer_id', 'title', 'thumb', 'content'],
            },
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            },
            editorConfig: {
                height: 200,
                placeholder: '',
                url: '/bbs/upload/image.bb',
                menus: [
                    'bold',
                    'fontSize',
                    'foreColor',
                    'backColor',
                    'link',
                    'image',
                ]
            },
            baseUrl: '/bbs/answer/index',
            baseAnswerUrl: '/bbs/answer/view',
            baseReplyUrl: '/bbs/reply/index',
            baseHotUrl: '/bbs/hot/index',
            answer: {},
            reply: {
                total: 0,
                data: []
            },
        }
    },
    watch: {
        '$store.state.editData.edit': function () {
            //默认情况下，编辑过来的数据，这里只需要更新四个字段
            let newData = {};
            this.control.fields.map(v => {
                if (this.$store.state.editData.data[v]) {
                    newData[v] = this.$store.state.editData.data[v];
                }
            });
            this.answer = Object.assign({}, this.answer, newData);
        },
        '$route.params.id': function () {
            //新添加问题的时候，或者单击相关的问题，会进入这里，先把原来评价先删除了
            this.reply.data.splice(0, 10000);
            this.getDetail();
        }
    },
    methods: {
        cancelReply() {
            this.control.edit = false;
        },
        confirmReply(index, data) {
            //设置最优解
            this.reply.data.splice(index, 1);
            this.answer.best_reply_id = data.reply_id;
            this.answer.status = 100;//设置成已经完成的状态
            this.$store.commit('setCurrentAnswer', this.answer);
            this.reply.data.unshift(data);
        },
        addComment(data) {
            let old = this.reply.data[data.index];
            old.comment_sum++;
            this.reply.data.splice(data.index, 1, old);
        },
        editAnswer() {
            this.$router.push({
                path: '/add',
                query: {
                    answer_id: this.answer.answer_id,
                }
            });
            // let editData = {};
            // //默认的编辑只编辑下面的四个字段
            // this.control.fields.map(v => {
            //     editData[v] = this.answer[v];
            // });
            // this.$store.commit('updateCurrentQuestion', {show: Math.random(), title: '编辑问题', data: editData});
            // let form = F.get('answer', 'editAnswer', this.answer);
            // form.action_name = 'editAnswer';
            // this.form = form;
        },
        addHot(type) {
            let answerId = this.getAnswerId();
            let confirm = false;
            let message;
            switch (type) {
                case 1:
                    confirm = !!this.answer.is_hot;
                    message = '确认取消热门吗?';
                    break;
                case 2:
                    message = '确认取消推荐吗?';
                    confirm = !!this.answer.is_recommend;
                    break;
            }
            if (confirm) {
                //如果是已经设置好了的数据，在取消的时候，需要提示用户
                this.$confirm(message, '操作确认').then(() => {
                    this.$bbs.delete(this.baseHotUrl, {data: {answer_id: answerId, type}}).then(() => {
                        this.$message.success('操作成功');
                        type == '1' ? this.answer.is_hot = false : this.answer.is_recommend = false;
                    });
                });
            } else {
                this.$bbs.post(this.baseHotUrl, {answer_id: answerId, type}).then(() => {
                    this.$message.success('操作成功');
                    type == '1' ? this.answer.is_hot = 1 : this.answer.is_recommend = 1;
                });
            }
        },
        verifySuccess(data) {
            //正常情况下，只会审核 type_id review_status 这两个字段
            this.answer = Object.assign({}, this.answer, {
                type_id: data.data.type_id,
                review_status: data.data.review_status
            });
        },
        addReply() {
            if (!storage.token()) {
                this.$store.state.loginDialog = true;
                return;
            }
            let content = this.$refs.editor.getHtml();
            //默认情况下，这里返回的内容是<p><br>></p>
            if (!this.$tools.stripHtmlTag(content)) {
                this.control.text = '请输入回复内容';
                return;
            }else{
                this.control.text='';
            }
            //如果有提示的错误信息，不提交
            if (this.control.text) return;
            this.$bbs.post(this.baseReplyUrl, {
                answer_id: this.getAnswerId(),
                content,
            }).then(result => {
                this.$message.success('回复成功!');
                this.control.edit = false;
                if (this.answer.best_reply_id) {
                    this.reply.data.splice(1, 0, result.data);
                } else {
                    this.reply.data.unshift(result.data);
                }
            });
        },
        editReply(index) {
            let form = F.get('answerReply', 'editReply', this.reply.data[index]);
            form.action_name = 'editReply';
            form.index = index;
            this.form = form;
        },
        submit: function (data) {
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.form.action_name) {
                case 'editAnswer':
                    this.$bbs.put(this.baseUrl, data.data).then(bbsData => {
                        this.$message.success('修改成功!');
                        this.answer = bbsData.data;
                    });
                    break;
                case 'editReply':
                    if (!data.data.content) return;
                    this.$bbs.put(this.baseReplyUrl, data.data).then(bbsData => {
                        this.$message.success('修改成功!');
                        let cache = this.reply.data[this.form.index];
                        if (!cache) return;
                        cache.content = bbsData.data.content;
                        cache.update_time = bbsData.data.update_time;
                    });
                    break;
            }
            this.form.show = false;
        },
        addQuestion: function () {
            this.control.edit = true;
        },
        change(html) {
            // console.log(html);
            switch (true) {
                case html.html.length > 4500:
                    this.control.text = '内容太多，已经装不下了~~';
                    break;
                case html.html.length < 1:
                    if (!this.control.text) {
                        //如果当前没有提示信息，不处理
                        return;
                    }
                    this.control.text = '内容太少啦';
                    break;
                default:
                    this.control.text = '';
            }
        },
        getAnswerId() {
            let id = this.$route.params.id;
            if (!id) return;
            return id.toString().split('.').shift();
        },
        getReply() {
            this.$bbs.get(this.baseReplyUrl, {
                params: {
                    answer_id: this.getAnswerId(),
                    reply_id: this.$route.query.reply_id,//在审核或者别的情况下，会回复的ID
                    comment_id: this.$route.query.comment_id,//在审核或者别的情况下，会评论的ID
                }
            }).then(data => {
                let need = data.data.data.filter(v => {
                    return v.reply_id !== this.answer.best_reply_id;
                });
                this.reply.total = data.data.total;
                this.reply.data.push(...need);
                setTimeout(() => {
                    //方便进行审核的定位
                    let id;
                    if (!this.$route.query.comment_id && !this.$route.query.reply_id) return;
                    if (this.$route.query.comment_id) {
                        id = 'comment_id_' + this.$route.query.comment_id;
                    } else {
                        id = 'reply_id_' + this.$route.query.reply_id;
                    }
                    let a = document.createElement('a');
                    a.href = '#' + id;
                    a.click();
                }, 100);
            });
        },

        getDetail() {
            let id = this.getAnswerId();
            this.$bbs.get(this.baseAnswerUrl, {
                params: {
                    answer_id: id,
                }
            }).then(data => {
                let answer = data.data;
                if (!answer || !answer.answer_id) {
                    this.$router.push({
                        path: '/404',
                    });
                    return;
                }
                //在页面的相关问题单击之后， 跳转到顶部去
                window.scrollTo(0, 0);
                this.answer = answer;
                document.title=answer.title;
                //增加这个方法是为了将评价中的提问者给显示出来。
                this.$store.commit('setCurrentAnswer', answer);
                if (data.data && data.data.best) {
                    //如果有最优的回答，放在这里来
                    this.reply.data.push(data.data.best);
                }
                this.getReply();
            }).catch(()=>{
                this.$router.push({
                    path: '/404',
                });
            });
        }
    }
}

</script>
<style scoped lang="less">
.base-width {

}

.box-s {
    box-shadow: 0 2px 2px 0 rgb(204 204 204 /50%);
}

.top-bg {
    height: 150px;
    margin-bottom: 15px;
    background: url("/static/bbs/images/menu/top-bg.png") top center no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    color: #eee;

    .part-list {
        width: 50%;
        padding: 20px;
    }

    .word-info {
        .main-info {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .description {
            font-size: 16px;
        }
    }

    .text-group {
        display: flex;

        .item {
            width: 32%;
            text-align: center;
            justify-content: space-around;

            .number {
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 18px;
            }
        }
    }
}

.re-editor {
    margin-top: 20px;
    //background-color: #fff;
    padding-bottom: 20px;
    .editor-footer {
        align-items: center;
        display: flex;

        .tip {
            flex: 1;
            font-size: 14px;
            color: #f56c6c;
        }

        > .btn-area {
            width: 260px;
            text-align: right;
            padding: 20px 20px 0 0;
        }
    }

}

.reply-list {
    margin-top: 20px;
    //background-color: #fff;
    padding: 20px;

    .top-sum {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #cccccc;
    }
}

.connected {
    margin-top: 15px;
    //background-color: #fff;
    padding: 15px;
}
</style>
